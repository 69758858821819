<template>
  <div>
    <b-card>
      <div class="d-flex justify-content-between align-items-center mb-1">
        <div class="card-title">
          Education History <br />
          <span style="font-size: 14px; color: darkgray">
            The last historical education will be used for further data
          </span>
        </div>
        <button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          class="btn btn-primary d-flex align-items-center"
          v-b-modal.add-education
        >
          <feather-icon size="16" icon="PlusIcon" class="mr-50" /> Add
        </button>
      </div>
      <b-card-body>
        <app-timeline>
          <app-timeline-item
            v-for="(education, index) in educations"
            :key="index"
            :title="education.institution_name"
            :subtitle="education.field_of_study"
            :from_date="education.start_date"
            :to_date="education.end_date"
            :target="['update_education', education]"
            @detail-data="detailData"
            variant="success"
          />
        </app-timeline>
      </b-card-body>
    </b-card>
    <b-modal
      id="add-education"
      hide-footer
      no-close-on-backdrop
      centered
      size="lg"
    >
      <div class="px-3 pb-3 pt-1">
        <div class="text-center mb-3" style="line-height: 1px">
          <h1 class="mb-2">Add Education History</h1>
          <span>
            The last historical education will be used for further data
          </span>
        </div>
        <form @submit.prevent="add_education()">
          <b-row>
            <b-col md="4">
              <b-form-group label="* Institute Name" label-for="institute-name">
                <b-form-input id="institute-name" v-model="institute_name" />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="* Degree" label-for="degree">
                <b-form-select id="Degree" v-model="degree">
                  <option value="">Select Degree</option>
                  <option value="junior" label="Junior High School"></option>
                  <option value="senior" label="Senior High School"></option>
                  <option value="diploma" label="Diploma"></option>
                  <option value="associate" label="Associate"></option>
                  <option value="bachelor" label="Bachelor"></option>
                  <option value="master" label="Master"></option>
                  <option value="doctor" label="Doctor"></option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="* Field of Study" label-for="field-of-study">
                <b-form-input id="field-of-study" v-model="field_of_study" />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="* Start Date" label-for="start-date">
                <flat-pickr
                  id="start-date"
                  v-model="start_date"
                  :config="{
                    dateFormat: 'Y-m-d',
                    altInput: true,
                    altFormat: 'F j, Y',
                    allowInput: true,
                    static: true,
                  }"
                  class="form-control"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="* End Date" label-for="end-date">
                <flat-pickr
                  id="end-date"
                  v-model="end_date"
                  class="form-control"
                  :config="{
                    dateFormat: 'Y-m-d',
                    altInput: true,
                    altFormat: 'F j, Y',
                    allowInput: true,
                    static: true,
                  }"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="* Grade" label-for="grade">
                <b-form-input id="grade" v-model="grade" />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Activities Societis"
                label-for="activities-societis"
              >
                <b-form-textarea
                  id="activities-societis"
                  v-model="activities_societis"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <label for="dropzone"
                >Files, ex: Ijazah or any and must be type img/pdf and max size
                is 6Mb
              </label>
              <vue-dropzone :options="dropzoneOptions" />
            </b-col>
            <b-col cols="12" class="text-right mt-3">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                type="submit"
              >
                <feather-icon size="16" icon="SaveIcon" class="mr-50" />
                Save
              </b-button>
            </b-col>
          </b-row>
        </form>
      </div>
    </b-modal>

    <b-modal
      id="update_education"
      hide-footer
      no-close-on-backdrop
      centered
      size="lg"
      data-bs-focus="false"
    >
      <div class="px-3 pb-3 pt-1">
        <div class="text-center mb-3" style="line-height: 1px">
          <h1 class="mb-2">Detail Education History</h1>
          <span>
            The last historical education will be used for further data
          </span>
        </div>
        <form @submit.prevent="edit_education(education)">
          <b-row>
            <b-col md="4">
              <b-form-group label="* Institute Name" label-for="institute-name">
                <b-form-input
                  id="institute-name"
                  v-model="education.institution_name"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="* Degree" label-for="degree">
                <b-form-select id="Degree" v-model="education.degree">
                  <option value="">Select Degree</option>
                  <option
                    value="junior"
                    :if="education.degree == 'junior' ? 'selected' : ''"
                    label="Junior High School"
                  ></option>
                  <option
                    value="senior"
                    :selected="education.degree == 'senior'"
                    label="Senior High School"
                  ></option>
                  <option
                    value="diploma"
                    :selected="education.degree == 'diploma'"
                    label="Diploma"
                  ></option>
                  <option
                    value="associate"
                    :selected="education.degree == 'associate'"
                    label="Associate"
                  ></option>
                  <option
                    value="bachelor"
                    :selected="education.degree == 'bachelor'"
                    label="Bachelor"
                  ></option>
                  <option
                    value="master"
                    :selected="education.degree == 'master'"
                    label="Master"
                  ></option>
                  <option
                    value="doctor"
                    :selected="education.degree == 'doctor'"
                    label="Doctor"
                  ></option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="* Field of Study" label-for="field-of-study">
                <b-form-input
                  id="field-of-study"
                  v-model="education.field_of_study"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="* Start Date" label-for="start-date">
                <flat-pickr
                  id="start-date"
                  v-model="education.start_date"
                  :config="{
                    dateFormat: 'Y-m-d',
                    altInput: true,
                    altFormat: 'F j, Y',
                    allowInput: true,
                    static: true,
                  }"
                  class="form-control"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="* End Date" label-for="end-date">
                <flat-pickr
                  id="end-date"
                  v-model="education.end_date"
                  class="form-control"
                  :config="{
                    dateFormat: 'Y-m-d',
                    altInput: true,
                    altFormat: 'F j, Y',
                    allowInput: true,
                    static: true,
                  }"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="* Grade" label-for="grade">
                <b-form-input id="grade" v-model="education.grade" />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <b-form-group
                label="Activities Societis"
                label-for="activities-societis"
              >
                <b-form-textarea
                  id="activities-societis"
                  v-model="education.activities_societis"
                />
              </b-form-group>
            </b-col>
            <b-col md="12">
              <label for="dropzone"
                >Files, ex: Ijazah or any and must be type img/pdf and max size
                is 6Mb
              </label>
              <vue-dropzone :options="dropzoneOptions" />
            </b-col>
            <b-col cols="12" class="text-right mt-3">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="outline-danger"
                type="submit"
                class="mr-1"
                @click="delete_education(education.id)"
              >
                <feather-icon size="16" icon="TrashIcon" class="mr-50" />
                Delete
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                type="submit"
              >
                <feather-icon size="16" icon="SaveIcon" class="mr-50" />
                Update
              </b-button>
            </b-col>
          </b-row>
        </form>
      </div>
    </b-modal>
  </div>
</template>

<style>
.modal .modal-header {
  background: none !important;
}

.dropzone {
  border: 2px dashed #0561bd;
  border-radius: 0.25rem;
  background: #fff;
  min-height: 50px;
  padding: 0rem !important;
  text-align: center;
  color: #adb5bd;
  font-size: 1.5rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.dropzone .dz-message {
  font-size: 1.5rem;
  font-weight: 500;
  padding: 0px;
  color: #adb5bd;
}
</style>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormTextarea,
  BFormCheckbox,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BInputGroup,
  BInputGroupAppend,
  BModal,
  VBModal,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";
import AppTimeline from "@core/components/app-timeline/AppTimeline.vue";
import AppTimelineItem from "@core/components/app-timeline/AppTimelineItem.vue";
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  components: {
    vueDropzone: vue2Dropzone,
    flatPickr,
    BModal,
    BCardBody,
    VBModal,
    AppTimeline,
    AppTimelineItem,
    BFormCheckbox,
    BFormSelect,
    BFormTextarea,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
    ToastificationContent,
  },
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
  data() {
    return {
      educations: [],
      education: {},

      institute_name: "",
      degree: "",
      field_of_study: "",
      start_date: "",
      end_date: "",
      grade: "",
      activities_societis: "",

      dropzoneOptions: {
        url: "https://httpbin.org/post",
        thumbnailWidth: 150,
        maxFilesize: 6,
        headers: { "My-Awesome-Header": "header value" },
      },
    };
  },
  props: {
    userData: {
      type: Object,
      default: () => {},
    },
  },
  mounted() {
    this.get_education();
  },
  methods: {
    detailData(data) {
      this.education = data;
    },

    get_education() {
      this.callApi({
        url: "educations/fetch",
        method: "get",
        params: {
          user_id: this.hashid(this.getAuthId()),
        },
        success: (response) => {
          response.result.forEach((element) => {
            this.educations.push(element);
          });
        },
      });
    },

    add_education() {
      this.callApi({
        url: "educations/create",
        method: "post",
        data: {
          user_id: this.getAuthId(),
          institution_name: this.institute_name,
          degree: this.degree,
          field_of_study: this.field_of_study,
          start_date: this.start_date,
          end_date: this.end_date,
          grade: this.grade,
          activities_societis: this.activities_societis,
        },
        success: (response) => {
          this.educations.push(response.result);
          this.$bvModal.hide("add-education");
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Success",
              text: "Education has been added",
              icon: "CheckIcon",
              variant: "success",
            },
          });
        },
      });
    },

    edit_education(education) {
      this.callApi({
        method: "POST",
        url: `/educations/update/${this.hashid(education.id)}`,
        data: {
          user_id: this.userData.id,
          institution_name: education.institution_name,
          degree: education.degree,
          field_of_study: education.field_of_study,
          start_date: education.start_date,
          end_date: education.end_date,
          grade: education.grade,
          activities_societis: education.activities_societis,
        },
        success: (response) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Success",
              text: "Education has been updated",
              icon: "CheckIcon",
              variant: "success",
            },
          });

          this.$bvModal.hide("update_" + education.id);
        },
        error: (error) => {
          console.log(error);
        },
      });
    },

    delete_education(id) {
      this.callApi({
        method: "DELETE",
        url: `/educations/delete/${this.hashid(id)}`,
        success: (response) => {
          this.educations = this.educations.filter((education) => {
            return education.id != id;
          });
          this.$bvModal.hide("update_education");
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Success",
              text: "Education has been deleted",
              icon: "CheckIcon",
              variant: "success",
            },
          });
        },
        error: (error) => {
          console.log(error);
        },
      });
    },
  },
};
</script>
<style>
@import url("https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css");
</style>