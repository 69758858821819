<template>
  <div>
    <b-card sub-title="Information Contract">
      <b-row>
        <b-col md="6">
          <b-form-group label="Contract Type" label-for="contract-type">
            <b-form-input
              id="contract-type"
              v-model="contract_type"
              placeholder="Contract Type"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Contract Status" label-for="contract-status">
            <b-form-input
              id="contract-status"
              v-model="status"
              placeholder="Contract Type"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group
            label="Contract Start Date"
            label-for="contract-start-date"
          >
            <b-form-input
              id="contract-start-date"
              v-model="contract_start_date"
              placeholder="Contract Start Date"
              readonly
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="Contract End Date" label-for="contract-end-date">
            <b-form-input
              id="contract-end-date"
              v-model="contract_end_date"
              placeholder="Contract End Date"
              readonly
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BFormSelect,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    Ripple,
  },
  props: {},
  data() {
    return {
      contract_type: "",
      status: "",
      contract_start_date: "",
      contract_end_date: "",
    };
  },
  computed: {},
  methods: {},
};
</script>
