<template>
  <div>
    <b-tabs
      vertical
      content-class="col-12 col-md-9 mt-1 mt-md-0"
      pills
      nav-wrapper-class="col-md-3 col-12"
      nav-class="nav-left"
    >
      <b-tab active>
        <template #title>
          <feather-icon icon="UserIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">General</span>
        </template>
        <account-setting-general :userData="userData" />
      </b-tab>
      <!-- <b-tab>
        <template #title>
          <feather-icon icon="ActivityIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Job & Department</span>
        </template>
        <account-setting-job :userData="userData" />
      </b-tab> -->
      <b-tab>
        <template #title>
          <feather-icon icon="DollarSignIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Sallary</span>
        </template>
        <account-setting-sallary :userData="userData" />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="BookIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Education</span>
        </template>
        <account-setting-education :userData="userData" />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="BriefcaseIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Career Experience</span>
        </template>
        <account-setting-career-experience :userData="userData" />
      </b-tab>
      <!-- <b-tab>
        <template #title>
          <feather-icon icon="FramerIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Contract</span>
        </template>
        <account-setting-contract :userData="userData" />
      </b-tab> -->
      <b-tab>
        <template #title>
          <feather-icon icon="ThermometerIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Insurance</span>
        </template>
        <account-setting-insurance :userData="userData" />
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon icon="LockIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Cretidential</span>
        </template>
        <account-setting-cretidential :userData="userData" />
      </b-tab>
      <!-- <b-tab>
        <template #title>
          <feather-icon icon="FileIcon" size="18" class="mr-50" />
          <span class="font-weight-bold">Attachment</span>
        </template>
        <account-setting-attachment :userData="userData" />
      </b-tab> -->
    </b-tabs>
  </div>
</template>

<script>
import { BTabs, BTab, BCard } from "bootstrap-vue";
import AccountSettingGeneral from "./AccountSettingGeneral.vue";
import AccountSettingJob from "./AccountSettingJob.vue";
import AccountSettingEducation from "./AccountSettingEducation.vue";
import AccountSettingCareerExperience from "./AccountSettingCareerExperience.vue";
import AccountSettingSallary from "./AccountSettingSallary.vue";
import AccountSettingContract from "./AccountSettingContract.vue";
import AccountSettingInsurance from "./AccountSettingInsurance.vue";
import AccountSettingCretidential from "./AccountSettingCretidential.vue";
import AccountSettingAttachment from "./AccountSettingAttachment.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  data() {
    return {
      userData: {},
    };
  },

  components: {
    ToastificationContent,
    BCard,
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingJob,
    AccountSettingCareerExperience,
    AccountSettingEducation,
    AccountSettingSallary,
    AccountSettingContract,
    AccountSettingInsurance,
    AccountSettingCretidential,
    AccountSettingAttachment,
  },

  created() {
    this.callApi({
      method: "GET",
      url: "/users/fetch",
      params: {
        id: this.hashid(JSON.parse(localStorage.getItem("sw_auth_data")).id),
      },
      success: (res) => {
        this.userData = res.result;
      },
      error: (err) => {
        console.log(err);
      },
    });
  },
};
</script>
