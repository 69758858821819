<template>
  <div>
    <!-- <b-card> {{ jobData }} </b-card> -->
    <b-card>
      <b-card sub-title="Sallary Information">
        <b-row>
          <b-col md="6">
            <b-form-group
              label="* Bank Account Number"
              label-for="bank-account-number"
            >
              <b-form-input
                id="bank-account-number"
                v-model="userData.salary.bank_account_number"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="* Name of Bank Account"
              label-for="name-of-bank-account"
            >
              <b-form-input
                id="name-of-bank-account"
                v-model="userData.salary.bank_name"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="* Bank of Issue" label-for="bank-os-issue">
              <b-form-input
                id="bank-os-issue"
                v-model="userData.salary.bank_of_issue"
              />
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group label="* NPWP Number" label-for="npwp-number">
              <b-form-input
                id="npwp-number"
                v-model="userData.salary.npwp_number"
              />
            </b-form-group>
          </b-col>
        </b-row>
      </b-card>
      <hr />
      <b-row>
        <b-col md="6">
          <b-card sub-title="Wages">
            <b-form-group label="Since Date" label-for="since-date">
              <flat-pickr
                id="since-date"
                v-model="userData.salary.signed_date"
                :config="{
                  dateFormat: 'Y-m-d',
                  altInput: true,
                  altFormat: 'F j, Y',
                  allowInput: true,
                }"
              />
            </b-form-group>
            <b-form-group label="Sallary Type" label-for="sallary-type">
              <b-form-select
                id="sallary-type"
                v-model="userData.salary.sallary_type"
              >
                <option value="dailly">Dailly</option>
                <option value="monthly">Monthly</option>
              </b-form-select>
            </b-form-group>
            <b-row>
              <b-col md="6">
                <b-form-group label="Sallary Form" label-for="sallary-form">
                  <b-form-select
                    id="sallary-form"
                    v-model="userData.salary.sallary_form"
                  >
                    <option value="permanent">Permanent</option>
                    <option value="interenship">Interenship</option>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Amount of Money"
                  label-for="amount-of-money"
                >
                  <b-form-input
                    type="number"
                    id="amount-of-money"
                    v-model="userData.salary.amout_sallary"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col md="6">
          <b-card sub-title="Allowances">
            <b-form-group label="Note" label-for="note">
              <b-form-textarea id="note" v-model="userData.salary.note" />
            </b-form-group>
            <b-row>
              <b-col md="6">
                <b-form-group label="Allowance Type" label-for="allowance-type">
                  <b-form-select
                    id="allowance-type"
                    v-model="userData.salary.allowance_type"
                  >
                    <option value="permanent">Permanent</option>
                    <option value="interenship">Interenship</option>
                  </b-form-select>
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Amount of Money"
                  label-for="amount-of-money"
                >
                  <b-form-input
                    type="number"
                    id="amount-of-money"
                    v-model="userData.salary.amout_allowance"
                  >
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
        <b-col cols="12" class="text-right">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-1"
          >
            Save changes
          </b-button>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import flatPickr from "vue-flatpickr-component";
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormSelect,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    flatPickr,
    BFormSelect,
    BButton,
    BForm,
    BFormGroup,
    BFormTextarea,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    Ripple,
  },
  props: {
    userData: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
