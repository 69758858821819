<template>
  <div>
    <!-- <b-card> {{ jobData }} </b-card> -->
    <b-card sub-title="Job & Department">
      <b-row>
        <b-col md="6">
          <b-form-group label="* Department" label-for="department">
            <v-select
              id="department"
              v-model="department"
              :options="departments"
              :reduce="(department) => department.id"
              label="name"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="* Sub Department" label-for="sub-department">
            <v-select
              id="sub-department"
              v-model="sub_department"
              :options="sub_departments"
              :reduce="(sub_department) => sub_department.id"
              label="name"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="* Job Level" label-for="job-level">
            <v-select
              id="job-level"
              v-model="job_level"
              :options="job_levels"
              :reduce="(job_level) => job_level.id"
              label="name"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="* Job Position" label-for="job-position">
            <v-select
              id="job-position"
              v-model="job_position"
              :options="job_positions"
              :reduce="(job_position) => job_position.id"
              label="name"
              disabled
            />
          </b-form-group>
        </b-col>
        <b-col md="6">
          <b-form-group label="* Employee Type" label-for="employee-type">
            <v-select
              id="employee-type"
              v-model="employee_type"
              :options="employee_types"
              :reduce="(employee_type) => employee_type.id"
              label="name"
              disabled
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";

export default {
  data() {
    return {
      departments: [],
      sub_departments: [],
      job_levels: [],
      job_positions: [],
      employee_types: [],

      department: "",
      sub_department: "",
      job_level: "",
      job_position: "",
      employee_type: "",
    };
  },

  props: {
    userData: {
      type: Object,
      default: () => {},
    },
  },

  directives: {
    Ripple,
  },
};
</script>
