<template>
  <div>
    <form @submit.prevent="submit_form">
      <b-card sub-title="Insurance">
        <b-row>
          <b-col md="6">
            <b-form-group label="* Insurance Type" label-for="insurance-type">
              <b-form-select id="insurance-type" v-model="insurance_type">
                <option value="BPJS Ketenagakerjaan">
                  BPJS Ketenagakerjaan
                </option>
                <option value="BPJS Kesehatan">BPJS Kesehatan</option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="* Insurance Number"
              label-for="insurance-number"
            >
              <b-form-input
                id="insurance-number"
                v-model="insurance_number"
                placeholder="Insurance Number"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col md="6">
            <b-form-group
              label="Second Insurance Type"
              label-for="second-insurance-type"
            >
              <b-form-select
                id="second-insurance-type"
                v-model="second_insurance_type"
              >
                <option value="BPJS Ketenagakerjaan">
                  BPJS Ketenagakerjaan
                </option>
                <option value="BPJS Kesehatan">BPJS Kesehatan</option>
              </b-form-select>
            </b-form-group>
          </b-col>
          <b-col md="6">
            <b-form-group
              label="Second Insurance Number"
              label-for="insurance-number"
            >
              <b-form-input
                id="insurance-number"
                v-model="second_insurance_number"
                placeholder="Insurance Number"
              />
            </b-form-group>
          </b-col>
          <b-col cols="12" class="text-right mt-3">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              type="submit"
            >
              Save changes
            </b-button>
          </b-col>
        </b-row>
      </b-card>
    </form>
  </div>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BRow,
  BCol,
  BCard,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BFormSelect,
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    Ripple,
  },
  props: {},
  data() {
    return {
      id: 0,
      insurance_type: "",
      insurance_number: "",
      second_insurance_type: "",
      second_insurance_number: "",
    };
  },
  mounted() {
    this.get_insurance();
  },
  methods: {
    get_insurance() {
      this.callApi({
        url: "insurances/fetch",
        params: {
          user_id: this.hashid(
            JSON.parse(localStorage.getItem("sw_auth_data")).id
          ),
        },
        method: "GET",
        success: (res) => {
          this.id = res.result[0].id;
          this.insurance_type = res.result[0].insurance_type;
          this.insurance_number = res.result[0].insurance_number;
          this.second_insurance_type = res.result[0].secondary_insurance_type;
          this.second_insurance_number =
            res.result[0].secondary_insurance_number;
        },
      });
    },

    submit_form() {
      this.callApi({
        url: `insurances/update/${this.hashid(this.id)}`,
        data: {
          user_id: JSON.parse(localStorage.getItem("sw_auth_data")).id,
          insurance_type: this.insurance_type,
          insurance_number: this.insurance_number,
          secondary_insurance_type: this.second_insurance_type,
          secondary_insurance_number: this.second_insurance_number,
        },
        method: "POST",
        success: (res) => {
          this.$bvToast.toast("Data has been updated", {
            title: "Success",
            variant: "success",
            solid: true,
          });
        },
      });
    },
  },
};
</script>
